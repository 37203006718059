@use "../../../global-styles/palette" as c;
@use "../../../global-styles/typography";

.payment-methods-icons {
    --item-width: 112px;
    
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    padding: 32px 20px;

    &__icons-list {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 4px;
    }

    &__item {
        box-sizing: border-box;
        display: flex;
        padding: 20px 10px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        width: 109px;
        border-radius: 12px;
        border: 1px solid rgba(255, 255, 255, 0.05);
    }

    &__title {
        @extend .caption;

        color: c.$dark-40;
    }

    &__note {
        color: c.$neutral-500;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 1.3;

        a {
            color: c.$pure-white;
        }
    }
}

.medium-view {
    .payment-methods-icons {
        padding-inline: 40px;

        &__icons-list {
            gap: 8px;
        }
        
        &__item {
            width: 142px;
        }
    }
}

.large-view {
    .payment-methods-icons {
        padding-inline: 0;

        &__icons-list {
            gap: 8px;
        }

        &__item {
            width: 144px;
        }
    }
}