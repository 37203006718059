@use "../../global-styles/variables" as v;
@use "../../global-styles/palette" as c;

.button-v2-wrapper:has(.tmx25-round-black-gray, .tmx25-round-white-gray, .tmx25-round-green-lightgreen, .tmx25-round-green-darkgreen) {
    width: 100%;
}

html[dir="rtl"] {
    .button-v2-wrapper {
        svg {
            transform: rotateY(180deg);
        }
    }
}

.button-v2-wrapper {
    margin: 0 auto;
    white-space: nowrap;
    border-radius: 38px;

    @mixin center-content {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        text-decoration: none;
        gap: 7px;

        svg {
            stroke: c.$dark-default;
        }
    }

    @include center-content;

    .button-v2-icon {
        display: block;
    }

    .button-v2-icon-hover {
        display: none;
    }

    .button-v2:hover .button-v2-icon {
        display: none;
    }

    .button-v2:hover .button-v2-icon-hover {
        display: block;
    }



    @mixin medium-button {
        @include center-content;
        text-decoration: none;
        font-style: normal;
        font-size: 16px;
        font-weight: 600;
        line-height: 16px;
        padding: 13px 20px;
        border-radius: 38px;
    }

    @mixin large-button {
        @include center-content;
        text-decoration: none;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        padding: 16px 32px;
        border-radius: 38px;
    }

    @mixin round-button {
        @include center-content;
        text-decoration: none;
        font-weight: 600;
        font-size: 18px;
        line-height: 26.05px;
        letter-spacing: -0.16px;
        padding: 16px 32px;
        border-radius: 38px;
        width: 100%;
        max-width: 308px;
    }

    @mixin tmx25-square-button {
        @include center-content;
        text-decoration: none;
        font-weight: 600;
        font-size: 16px;
        line-height: 26.05px;
        letter-spacing: -0.16px;
        padding: 8px 20px;
        border-radius: 38px;
    }


    .tmx25-round-black-gray {
        @include round-button;
        background-color: c.$dark-default;
        color: c.$pure-white;

        svg {
            stroke: c.$pure-white;
        }
    }

    .tmx25-round-black-gray:hover {
        background-color: c.$dark-80;
        color: c.$pure-white;

        svg {
            stroke: c.$pure-white;
        }
    }

    .tmx25-round-white-gray {
        @include round-button;
        background-color: c.$neutral-accent-60;
        color: c.$dark-default;
    }

    .tmx25-round-white-gray:hover {
        background-color: c.$dark-80;
        color: c.$pure-white;

        svg {
            stroke: c.$pure-white;
        }
    }

    .tmx25-round-green-darkgreen {
        @include round-button;
        background-color: c.$tm-dark-btn-green;
        color: c.$pure-white;

        svg {
            stroke: c.$pure-white;
        }
    }

    .tmx25-round-green-darkgreen:hover {
        background-color: c.$tm-dark-btn-hover-green;
        color: c.$pure-white;

        svg {
            stroke: c.$pure-white;
        }
    }

    .tmx25-round-green-lightgreen {
        @include round-button;
        background-color: c.$tm-green-default;
        color: c.$dark-default;

        svg {
            stroke: c.$dark-default;
        }
    }

    .tmx25-round-green-lightgreen:hover {
        background-color: c.$tm-light-btn-hover-green;
        color: c.$dark-default;

        svg {
            stroke: c.$dark-default;
        }
    }

    .tmx25-round-green-lightgreen--width-not-100percent {
        @extend .tmx25-round-green-lightgreen;
        width: auto;
    }


    .tmx25-square-white-purple {
        @include tmx25-square-button;
        background-color: c.$pure-white;
        color: c.$dark-default;
    }

    .tmx25-square-white-purple:hover {
        background-color: c.$tm-dark-btn-hover-purple;
        color: c.$pure-white;
    }

    .tmx25-square-white-green {
        @include tmx25-square-button;
        background-color: c.$pure-white;
        color: c.$dark-default;
    }

    .tmx25-square-white-green:hover {
        background-color: c.$tm-green;
        color: c.$pure-white;
    }


    .tmx25-square-white-blue {
        @include tmx25-square-button;
        background-color: c.$pure-white;
        color: c.$dark-default;
    }

    .tmx25-square-white-blue:hover {
        background-color: c.$tm-dark-btn-hover-blue;
        color: c.$pure-white;
    }


    .tmx25-square-green-green {
        @include tmx25-square-button;
        background-color: c.$tm-btn-hover-darkgreen;
        color: c.$dark-default;
    }

    .tmx25-square-green-green:hover {
        background-color: c.$tm-btn-hover-palegreen;
        color: c.$dark-default;
    }


    .tmx25-square-white-lightblue {
        @include tmx25-square-button;
        background-color: transparent;
        color: c.$dark-default;
    }

    .tmx25-square-white-lightblue:hover {
        background-color: c.$tm-btn-hover-lightblue;
        color: c.$dark-default;
    }

    .tmx25-square-lightpurple {
        @include tmx25-square-button;

        background-color: c.$tm-btn-lightpurple;
        color: c.$dark-default;
    }

    .tmx25-square-lightpurple:hover {
        @include tmx25-square-button;

        background-color: c.$tm-btn-hover-lightpurple;
        color: c.$dark-default;
    }

    .tmx25-square-lightpurple-squared {
        @include tmx25-square-button;
        color: c.$dark-default;
        background-color: c.$tm-btn-lightpurple;
        border-radius: 6px;
        padding: 8px 43px;
    }

    .cta-medium-wbg {
        @include medium-button;
        background-color: c.$tm-green-default;
        color: c.$dark-default;
    }

    .cta-medium-wbg:hover {
        background-color: c.$tm-green-120;
    }

    .cta-medium-dbg {
        @include medium-button;
        background-color: c.$tm-green-default;
        color: c.$dark-default;
    }

    .cta-medium-dbg:hover {
        background-color: c.$pure-white;
        color: c.$dark-default;
    }


    .ghost-medium-wbg {
        @include medium-button;
        border: 1px solid c.$dark-default;
        color: c.$dark-default;
    }

    .ghost-medium-wbg:hover {
        background-color: c.$dark-default;
        color: c.$pure-white;
    }

    .ghost-medium-dbg {
        @include medium-button;
        border: 1px solid c.$pure-white;
        color: c.$pure-white;
    }

    .ghost-medium-dbg:hover {
        background-color: c.$pure-white;
        color: c.$dark-default;
    }


    .secondary-medium-wbg {
        @include medium-button;
        background-color: c.$dark-10;
        color: c.$dark-default;
    }

    .secondary-medium-wbg:hover {
        background-color: c.$dark-default;
        color: c.$pure-white;
    }

    .secondary-medium-dbg {
        @include medium-button;
        background-color: c.$dark-10;
        color: c.$dark-default;
    }

    .secondary-medium-dbg:hover {
        background-color: c.$pure-white;
        color: c.$dark-default;
    }

    .cta-large-wbg {
        @include large-button;
        background-color: c.$tm-green-default;
        color: c.$dark-default;
    }

    .cta-large-wbg:hover {
        background-color: c.$tm-green-120;
    }

    .cta-large-dbg {
        @include large-button;
        background-color: c.$tm-green-default;
        color: c.$dark-default;
    }

    .cta-large-dbg:hover {
        background-color: c.$pure-white;
        color: c.$dark-default;
    }


    .ghost-large-wbg {
        @include large-button;
        border: 1px solid c.$dark-default;
        color: c.$dark-default;
    }

    .ghost-large-wbg:hover {
        background-color: c.$dark-default;
        color: c.$pure-white;
    }

    .ghost-large-dbg {
        @include large-button;
        border: 1px solid c.$pure-white;
        color: c.$pure-white;
    }

    .ghost-large-dbg:hover {
        background-color: c.$pure-white;
        color: c.$dark-default;
    }


    .secondary-large-wbg {
        @include large-button;
        background-color: c.$dark-10;
        color: c.$dark-default;
    }

    .secondary-large-wbg:hover {
        background-color: c.$dark-default;
        color: c.$pure-white;
    }

    .secondary-large-dbg {
        @include large-button;
        background-color: c.$dark-10;
        color: c.$dark-default;
    }

    .secondary-large-dbg:hover {
        background-color: c.$pure-white;
        color: c.$dark-default;
    }

    .commonHoverStyle {
        border-color: c.$tm-26-gray-line-color;
        background-color: transparent;
        color: c.$dark-default;
    }

    .tmx2-6-rounded-with-border-small {
        @extend .ghost-large-wbg;
        font-size: 14px;
        line-height: 14px;
        padding: 12px 16px;
        border-radius: 48px;
        border: 1px solid c.$darker-gray-line-color;

        &:hover {
            @extend .commonHoverStyle;
        }
    }

    .tmx2-6-rounded-with-border-medium {
        @extend .ghost-large-wbg;
        font-size: 16px;
        line-height: 16px;
        padding: 13px 20px;
        border: 1px solid c.$darker-gray-line-color;

        &:hover {
            @extend .commonHoverStyle;
        }
    }

    .tmx2-6-rounded-with-border-large {
        @extend .ghost-large-wbg;
        line-height: 18px;
        padding: 15px 24px;
        border: 1px solid c.$darker-gray-line-color;

        &:hover {
            @extend .commonHoverStyle;
        }
    }

    .tmx2-6-rounded-with-border-extra-large {
        @extend .ghost-large-wbg;
        padding: 19px 28px;
        border: 1px solid c.$darker-gray-line-color;
        line-height: 18px;

        &:hover {
            @extend .commonHoverStyle;
        }
    }
}