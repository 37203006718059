.social-bar-logo-livechat {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 20px;
    padding: 20px;

    & > * {
        flex: 0 1 auto; 
    }

    &__livechat-cta {
        flex: 1;
    }

    .logo-wrapper {
        justify-content: center;
    }
}

@mixin social-bar-logo-livechat-large {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    column-gap: 20px;

    .logo-wrapper {
        justify-content: flex-start;
    }
}

.medium-view {
    .social-bar-logo-livechat {
        padding-inline: 40px;
    }
}

.large-view {
    .social-bar-logo-livechat {
        padding-inline: 0;
    }
}

.large-view {
    .social-bar-logo-livechat {
        @include social-bar-logo-livechat-large;
    }
}

@media (min-width: 800px) and (orientation: landscape) {
    .social-bar-logo-livechat {
        @include social-bar-logo-livechat-large;
    }
}
