@use "../../global-styles/palette" as c;
@use "../../global-styles/typography";

.footer-v2dot6 {
    display: flex;
    flex-direction: column;

    &__legal-info {
        @extend .caption;

        padding: 32px 20px;
        color: #828fa1;

        & > * {
            @extend .caption;
        }

        p:not(:first-child) {
            margin-top: 16px;
        }

        a,
        a:hover,
        a:link,
        a:active,
        a:visited {
            color: c.$pure-white;
            text-decoration: underline;
            transition: ease 0.2s;
            word-break: break-word;
        }
    }
}

.small-view,
.medium-view {
    .footer-v2dot6 {
        .g-container {
            margin: 0;
        }
    }
}

.medium-view {
    .footer-v2dot6 {
        &__legal-info {
            padding-inline: 40px;
        }
    }
}

.large-view {
    .footer-v2dot6 {
        &__legal-info {
            padding-inline: 0;
        }
    }
}
